
.class-admin{
  padding: 0px 20px;
  box-sizing: border-box;
  height: 100%;
  display: flex;
  flex-direction: column;
  .class-admin-header{
    display: flex;
    justify-content: flex-end;
  }
}
